import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Brand from '../Brand';
import Header from './header';
import { Col, Container, Row } from 'react-bootstrap';
import { Sidebar } from './sidebar';
import { Helmet } from 'react-helmet';
import { getApiUserMetadata, completeStep } from '@capp.micro/shared/api';
import { useAuth0 } from '@auth0/auth0-react';
const Layout = ({ children }) => {
  const isBrowser = typeof window !== 'undefined';
  
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const stepArray = []
  useEffect(() => {
    const updateUserMetadata = async () => {
      completeStep(Brand, user, getAccessTokenSilently, setUserMetadata, false);
    };

    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
    updateUserMetadata();
  }, [getAccessTokenSilently, user]);

  const stepNames = ['profileComplete', 'step3Complete', 'step6Complete']

  if (isAuthenticated && userMetadata) {
   stepNames.forEach(step => userMetadata[`${Brand.FLAG_NAME}${step}`] ? stepArray.push(true) : stepArray.push(''))
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
          'xmlns:og': 'http://ogp.me/ns#',
          'xmlns:fb': 'http://ogp.me/ns/fb#',
        }}
      >
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        ....
      </Helmet>

      <Header />
      {isBrowser && window.location.pathname === '/' && <main>{children}</main>}
      {isBrowser && window.location.pathname === '/testing-sign-up' && (
        <main>{children}</main>
      )}

      {isBrowser && window.location.pathname !== '/' && (
        <div
          style={{
            margin: `0 auto`,
            height: '90%',
            width: '100%',
            padding: '0',
          }}
        >
          <main>
            <Container style={{ maxWidth: '100%', padding: '0' }}>
              <Row style={{ maxWidth: '100%', padding: '0' }}>
                <Col
                  className="sidebar-wrapper"
                  xs={12}
                  lg={2}
                  style={{ maxWidth: '100%', padding: '0' }}
                >
                  <Sidebar
                    useApiEndpoints
                    item1="Pre-skilling Zone"
                    link1="/dashboard/"
                    item2="Learn About You"
                    link2="/learn-about-you/"
                    item3="Your Skills & Strengths"
                    link3="/your-skills-&-strengths/"
                    item4="Resume Writing Tips"
                    link4="/resume-tips/"
                    item5="Get Seen on LinkedIn"
                    link5="/linked-in/"
                    extraLinks={[
                      {
                        url: '/be-recruitment-ready/',
                        text: 'Be Recruitment Ready',
                      },
                      {
                        url: '/insurance-career-&-you/',
                        text: 'Insurance Career & You',
                      },
                      { url: '/faqs/', text: 'FAQs' },
                      { url: '/policies/', text: 'Policies' },
                    ]}
                    navClass1={''}
                    navClass2={''}
                    navClass3={''}
                    image={Brand.POWERED_BY_CAPP_LOGO}
                  />
                </Col>
                <Col
                  className="main"
                  xs={12}
                  lg={10}
                  style={{
                    padding: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                  }}
                >
                  {children}
                </Col>
              </Row>
            </Container>
          </main>
        </div>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
