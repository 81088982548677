import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardAlternate from '../components/sidebar-card-alternate';
import { getApiUserMetadata } from '@capp.micro/shared/api';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';

export function PracticeQuestions() {
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const stepArray = []
  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';

  const stepNames = ['step4Complete', 'step5Complete', 'step6Complete']

  if (isAuthenticated && userMetadata) {
   stepNames.forEach(step => userMetadata[`${Brand.FLAG_NAME}${step}`] ? stepArray.push(true) : stepArray.push(''))
  }

  const createSidebarCard = (alt, cardClass, heading, text, step, link) => (
    <SidebarCardAlternate
      alt={alt}
      class={cardClass}
      heading={heading}
      text={text}
      disabled={''}
      buttonText={step ? 'Retake' : 'Start Now'}
      link={link}
    />
  );

  const cogSidebarCard = () => {
    const step = stepArray[1];
    const link = `${Brand.ASSESSMENT_LINKS.COG_LINK}&cuid=${userID}&fname=${fname}&lname=${lname}&email=${email}&auid=${Brand.AUID}`;
  
    return createSidebarCard(
      Brand.BRAND_NAME,
      'sidebar-card card-five',
      ['Practice Questions', <br key="br1" />, <br key="br2" />],
      ["Practice and learn how to respond to cognitive questions."],
      step,
      link
    );
  };

  const jobSimSidebarCard = () => {
    const step = stepArray[0];
    const link = `${Brand.ASSESSMENT_LINKS.WORK_SIM_LINK}&cuid=${userID}&fname=${fname}&lname=${lname}&email=${email}&auid=${Brand.AUID}`;
  
    return createSidebarCard(
      Brand.BRAND_NAME,
      'sidebar-card card-four',
      ['Practice Job Simulation', <br key="br1" />, <br key="br2" />],
      ["Experience a generic work simulation and how you may be tested when applying for jobs."],
      step,
      link
    );
  };


  const sbiSidebarCard = () => {
    const step = stepArray[2];
    const link = `${Brand.ASSESSMENT_LINKS.TRIAL_LINK}&cuid=${userID}&fname=${fname}&lname=${lname}&email=${email}&auid=${Brand.AUID}`;
  
    return createSidebarCard(
      Brand.BRAND_NAME,
      'sidebar-card card-six',
      ['Interview Prep', <br key="br1" />, <br key="br2" />],
      ["Support designed to help you communicate your unique value."],
      step,
      link
    );
  };



  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE4_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-three capp-gradient"
        heading="Be Recruitment Ready"
        subheading=""
      />
      <Container className="cards-wrapper">
        <Row>
        <Col xs={12} lg={4}>
          {cogSidebarCard()}
          </Col>
          <Col xs={12} lg={4}>
          {jobSimSidebarCard()}
          </Col>
          <Col xs={12} lg={4}>
          {sbiSidebarCard()}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default withAuthenticationRequired(PracticeQuestions, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
