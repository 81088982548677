import React from "react"
import { Col, Container, Row, } from "react-bootstrap"
import {motion} from "framer-motion"

export function SidebarCardAlternate(props) {

  return (
    
    <Container className={props.class}>
      <div className="card-image">
      <Row>
            <Col xs={12}>
            <h2>{props.heading}</h2>
            </Col>
        </Row>
      </div>
            <Row>
                <Col xs={12} className="card-text">
                    <p>{props.text}</p>
                    <motion.button
                      disabled={props.disabled}
                      className="btn btn-primary"
                      variant="primary"
                      whileHover={props.hover}
                      whileTap={props.tap}
                      onClick={(e) => {
                        window.location.href = props.link; e.target.disabled = "true";
                        }} >
                      {props.buttonText}
                  </motion.button>
                </Col>
            </Row>
        </Container>
  
  )
}


export default SidebarCardAlternate
