import React from 'react';
import {withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardCard from '../components/sidebar-card-card';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';


export function AccelerateSkills() {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE15_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-fourteen capp-gradient"
        heading="Boost Your Career with LinkedIn: A Top 10 of Tips and Best Practices"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p>
          LinkedIn is a powerful tool for professionals looking to network, job hunt, or enhance their online presence. It's a digital portfolio showcasing your skills, experiences, and aspirations. Here are some best practices for building an impactful LinkedIn profile, especially for those at the start of their career journey.
          </p>
          <p />
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Customize Your URL']}
              text={[
                'Make your profile easy to find by customizing your LinkedIn URL. Use your name without spaces, and include it on your business cards, resume, or email signature to drive traffic to your profile.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Craft an Engaging Headline']}
              text={[
                "Your headline appears under your name in searches and is often the first thing people read. It defaults to your current job title, but you can customize it. Make it catchy, concise, and indicative of your career aspirations.",
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Use a Professional Photo']}
              text={[
                'First impressions count. A professional and friendly photo can result in up to 21 times more views. Wear business attire, and ensure the background is clean and neutral.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['4.']}
              heading={['Write a Compelling Summary']}
              text={[
                "This is your elevator pitch. Describe who you are, what you can do, and what you're seeking. Use keywords related to your industry for better visibility in searches. Show some personality, but maintain a professional tone.",
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['5.']}
              heading={['Showcase Your Experience']}
              text={[
                'List your roles, including internships and part-time jobs, with clear and concise bullet points highlighting your responsibilities and achievements. Use action verbs and quantify results where possible. Don’t be afraid to show progression over perfection - learning and growth are valuable too.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['6.']}
              heading={['Highlight Your Skills']}
              text={[
                'Include a comprehensive list of skills - technical, soft, and transferable. Endorsements for these skills can improve your credibility. ',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['7.']}
              heading={['Ask for Recommendations']}
              text={[
                'Recommendations provide social proof of your abilities. Ask past colleagues, managers, or teachers to write one for you. A good practice is to write recommendations for others as well, as this can often lead to reciprocation.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['8.']}
              heading={['Showcase Your Education and Certifications']}
              text={[
                'Include all your educational qualifications and any certifications or short courses relevant to your career. This can demonstrate lifelong learning - a trait highly valued by employers.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['9.']}
              heading={['Engage with Your Network']}
              text={[
                'LinkedIn is a social network - so be social! Join groups, follow influencers, comment on posts, and share relevant content. Regular interaction will keep your profile visible to your network.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
        <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['10.']}
              heading={['Keep it Current']}
              text={[
                'Regularly update your profile to reflect any new skills, experiences, or changes in career focus. Even if you’re not job hunting, maintaining an updated profile keeps you ready for any opportunity that may arise.',
              ]}
            />
          </Col>
        </Row>
        <p />
        <p />
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p>
        Remember, LinkedIn is more than just an online resume. It's a platform to showcase your professional story and connect with opportunities. Spend time crafting a strong profile - the investment can significantly pay off in your career progression.
        </p>
        <Button onClick={() => navigate(-1)}>Back</Button>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(AccelerateSkills, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
