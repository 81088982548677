import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

export function Faqs() {
  
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE6_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-six capp-gradient"
        heading="Frequently Asked Questions"
        subheading=""
      />
      <Container className="cards-wrapper">
        <Row>
         

          <Col xs={12}>
            <p></p>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What device should I use to take the assessment?
                </Accordion.Header>
                <Accordion.Body>
                  Please follow the guidance provided in your invite and the
                  assessment instructions for the best chance at demonstrating
                  your strengths. We typically recommend completing the
                  assessment on your laptop or desktop device for the best
                  experience. If this is not possible, then completion on a
                  mobile or tablet device is available for some of our
                  assessments and will be referenced in your invite. Whichever
                  device you use, please ensure that your internet connection is
                  strong and stable before you start the assessment.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Additional requirements for assessments with video questions
                </Accordion.Header>
                <Accordion.Body>
                  You will need to complete on a device with a working camera
                  and microphone. If you are unable to access such a device,
                  please contact the organization that asked you to complete the
                  assessment.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>What browser should I use?</Accordion.Header>
                <Accordion.Body>
                  For the best experience, we strongly recommend you use the
                  latest version of Google Chrome. If not available, then we
                  recommend the latest version of Firefox, Opera, Safari or
                  Microsoft Edge.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How do I turn off my pop-up blocker?
                </Accordion.Header>
                <Accordion.Body>
                  Pop-up blockers on your device can prevent your assessment
                  window from opening; so they should always be disabled.
                  Browsers such as Google Chrome have a pop-up blocker
                  pre-installed. To find out how to disable your pop-up blocker,
                  please follow the instructions provided on the help page for
                  the web browser you are using. This may also be the case with
                  some anti-virus software packages and toolbars. Please read
                  the manual for your anti-virus software to find out how you
                  can temporarily disable your pop-up blockers.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  How do I enable cookies on my browser?
                </Accordion.Header>
                <Accordion.Body>
                  Please ensure that cookies are enabled in your browser, since
                  our assessments use cookies to protect your assessment data.
                  To do this, please follow the instructions provided on the
                  help page for the web browser you are using.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  How can I clear my cookies, cache and browsing history?{' '}
                </Accordion.Header>
                <Accordion.Body>
                  On occasion, you may be asked to do this to ensure the
                  assessment loads correctly. To do this, please follow the
                  instructions provided on the help page for the web browser you
                  are using.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <h3 style={{paddingLeft: '0px', background: "transparent", marginTop: "30px"}}>Support with technical issues</h3>
            <p>Internet connection issues</p>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What if I lose internet connection during the assessment?
                </Accordion.Header>

                <Accordion.Body>
                  You can reload the page and complete the rest of the
                  assessment once you have internet connection again. Depending
                  on the assessment, you’ll either be taken back to the question
                  you were on when you lost internet connection, or you will be
                  taken to the home page and can navigate back to the question
                  yourself. Any video responses you have already submitted, and
                  any other responses you have already pressed ‘Save’ on, will
                  have been saved.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  I had technical issues during a timed assessment
                </Accordion.Header>
                <Accordion.Body>
                  If you have encountered a significant interruption that
                  impacts your ability to complete your assessment in the time
                  available, you should notify the organization who has asked
                  you to complete the assessment. It is at the organization’s
                  discretion to decide whether to reset your assessment.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  I am recording a video response and get the message ‘Warning –
                  You have used all of your recording attempts. You will not be
                  able to record a response. Please contact Support’. What do I
                  do?
                </Accordion.Header>
                <Accordion.Body>
                  This is caused by persistent internet connection issues that
                  repeatedly prevent you from successfully recording a video
                  response. To progress, please contact us for additional
                  support using the contact details in your assessment,
                  including the following information: The text of the question
                  you are encountering this message for. A full screenshot of
                  the webpage. The name of the organization you are taking the
                  assessment for. We also recommend exploring how you can
                  improve your internet connection for your next attempt at
                  completing the assessment.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  I get the message ‘Error – An error occurred, please try
                  again’. What do I do?
                </Accordion.Header>
                <Accordion.Body>
                  If you are encountering this on a free-text question (where
                  you must type in your response), do not refresh the webpage
                  and please contact us for additional support using the contact
                  details in your assessment, including the following
                  information: The name of the organization you are taking the
                  assessment for. A full screenshot of the webpage. The link you
                  are taking the assessment on (begins with the text ‘apitake’).
                  If you are seeing this error while trying to complete any
                  other type of question, refresh the page and try again. If
                  this does not resolve the issue, please contact our Support
                  team here with the above details.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <h3 style={{paddingLeft: '0px', background: "transparent", marginTop: "30px"}}>Issues with camera and microphone set-up</h3>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  I cannot start the video question because no camera/microphone
                  are listed on settings. What do I do?
                </Accordion.Header>

                <Accordion.Body>
                  To allow permission for camera access in the
                  browser, please use the browser guidance below: For Google
                  Chrome:
                  https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en-GB&oco=0
                  For Firefox:
                  https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions
                  For Opera:
                  https://help.opera.com/en/latest/web-preferences/#camera For
                  Microsoft Edge (at the bottom of the page):
                  https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy
                  For Safari:
                  https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac
                  To test whether your camera/microphone functionality works
                  outside of the assessment, please visit the following website
                  and follow the on-screen instructions:
                  https://webrtc.github.io/samples/src/content/devices/input-output/
                  Please exit the assessment to do this, and return to it once
                  the issues have been resolved.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  The video setup screen shows the message ‘I can’t see you’.
                  What do I do?
                </Accordion.Header>
                <Accordion.Body>
                  Use the camera/microphone selection at the bottom of the video
                  feed to select the best device and then click the ‘Save
                  Settings’ button to proceed. To help the camera identify your
                  face: Follow the instructions on your video feed. Try moving
                  somewhere with better lighting; too much light from the back
                  or above may impact the camera’s ability to identify your
                  face. Keep your face clearly in view and look directly into
                  the camera.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  I am at the video setup stage and get the confirmations ‘I can
                  see you’ and ‘I can hear you’. However, I am unable to start
                  recording my video response. What do I do?
                </Accordion.Header>
                <Accordion.Body>
                  To progress, you need to close the ‘Camera and Microphone
                  Settings’ by clicking on ‘Save Settings’. The ‘Record’ button
                  will then become active, and you can progress.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  I am recording a video response and get the message ‘Media
                  Manager Inactive’. What do I do?
                </Accordion.Header>
                <Accordion.Body>
                  To progress, you must ensure your camera and microphone
                  devices are not being used by another application (e.g. Zoom,
                  Skype). We also recommend using Google Chrome with Incognito
                  mode, this will allow you to give the correct permissions to
                  undertake the assessment without further issues. For
                  instructions on using Incognito mode, visit
                  https://support.google.com/chrome/answer/95464?co=GENIE.Platform%3DDesktop&hl=en&oco=0
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <h3 style={{paddingLeft: '0px', background: "transparent", marginTop: "30px"}}>Assessment advice</h3>

            <p>Accessibility and adjustments</p>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Can I change the text font size for my assessment?
                </Accordion.Header>

                <Accordion.Body>
                  To increase/decrease the font size, press and hold the “Ctrl”
                  and “+”/”-“ buttons on your keyboard until the text is your
                  preferred size.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  I require adjustments / accommodations to the format of the
                  assessment; who should I contact?
                </Accordion.Header>
                <Accordion.Body>
                  If you have a condition that means you need the format of the
                  assessment to be adjusted for you, please contact the
                  organization that has asked you to complete the assessment.
                  They will be happy to explore this with you.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <h3 style={{paddingLeft: '0px', background: "transparent", marginTop: "30px"}}>General advice</h3>

            <p>
              For advice on preparing for a Cappfinity assessment and access to
              our practice assessments, please visit our page here.
            </p>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  How much time do I have to complete the assessment?
                </Accordion.Header>

                <Accordion.Body>
                  You will be notified of any time limits in your invites to
                  complete your assessment, as well as in the assessment’s
                  instructions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How much time do I have to prepare for and answer each
                  question?
                </Accordion.Header>
                <Accordion.Body>
                  If applicable, you will be notified of any time limits for
                  your preparation and response as you attempt each question.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Do I have to complete each assessment in one go, or can I
                  leave and come back to it?
                </Accordion.Header>
                <Accordion.Body>
                  We typically recommend you complete in one sitting for optimal
                  focus. However, most untimed assessments will allow you to
                  return to the assessment later if you have been interrupted or
                  need more time. If you have an invite to complete multiple
                  assessments, then you might benefit from a break between them.
                  This will support you to perform at the best of your ability,
                  without becoming too tired.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How do I return to my assessment, if I closed the browser?
                </Accordion.Header>
                <Accordion.Body>
                  You can return to your assessment by clicking on the link in
                  your invite. Any video responses you have already submitted,
                  and any other responses you have already pressed ‘Save’ on,
                  will have been saved. You will not be able to return to the
                  assessment, if you have already completed and submitted it.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  How will I know when I’ve finished the assessment?
                </Accordion.Header>
                <Accordion.Body>
                  The last screen of the assessment confirms that your
                  assessment is complete and that your responses have been
                  submitted.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  When will I know the outcome of the assessment?
                </Accordion.Header>
                <Accordion.Body>
                  The organization that asked you to take the assessment will
                  share the outcome after reviewing your application. If you
                  have been waiting for longer than suggested in your email
                  communications, please reach out to the organization that
                  invited you to complete the assessment.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Can I watch videos multiple times?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, if your assessment contains a video. If your question is
                  untimed, you can watch the video relating to the current
                  question as many times as you would like. Some questions
                  requiring video responses might be timed, in which case you
                  will be able to re-watch the video within the corresponding
                  time limit.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  Can I answer video and written questions in my native language
                  (even if the assessment is in a different language)?
                </Accordion.Header>
                <Accordion.Body>
                  If not stated otherwise in the communications sent out by the
                  organization, we ask you to respond to the questions in the
                  language of the assessment.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <h3 style={{paddingLeft: '0px', background: "transparent", marginTop: "30px"}}>Providing video responses</h3>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Will I get a chance to practice my response?
                </Accordion.Header>

                <Accordion.Body>
                  You will have the opportunity to practice a video response.
                  You will first be asked to verify that your camera and
                  microphone are working as expected by recording a short video.
                  You will then be able to play it back and check the quality of
                  your video and audio. You can record this video several times,
                  until you can see and hear yourself well. In some cases, you
                  will also be asked to respond to a non-assessed, timed
                  question, that follows a more similar format to the questions
                  you will be asked later on in the assessment. The purpose of
                  this second question is to better prepare you for the
                  assessment.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Will I be able to re-record a video response?
                </Accordion.Header>
                <Accordion.Body>
                  Video responses for assessed questions cannot be re-recorded,
                  but you have time to prepare your answer ahead of the
                  question. The exact time will be specified as you attempt the
                  question.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Can I hide the camera feed (where I see myself) while I am
                  recording a video answer?
                </Accordion.Header>
                <Accordion.Body>
                  No. The camera feed cannot be hidden as it is important that
                  you receive feedback about your positioning in front of the
                  camera. However, we minimize the camera feed to be of minimal
                  distraction for you.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <h3 style={{paddingLeft: '0px', background: "transparent", marginTop: "30px"}}>Data questions</h3>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Where can I view Cappfinity’s Privacy Policies?
                </Accordion.Header>

                <Accordion.Body>
                  A Privacy Policy, User Agreement and Cookie Notice will be
                  provided to you before you start your assessment. You can also
                  access these policies and others from the bottom of our
                  company page.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Who does Cappfinity share my personal information with?
                </Accordion.Header>
                <Accordion.Body>
                  Details of who we may share your personal data with and why
                  are provided at the point of registration. In brief: To our
                  group companies, third party services providers and partners
                  who provide data processing services to us. For further
                  details please see our Subprocessors Notice. The organization
                  who has requested you to take the assessment (if applicable).
                  To any competent law enforcement body, regulatory, government
                  agency, court or other third party where we believe disclosure
                  is necessary (i) as a matter of applicable law or regulation,
                  (ii) to exercise, establish or defend our legal rights, or
                  (iii) to protect your vital interests or those of any other
                  person. To a potential buyer (and its agents and advisers) in
                  connection with any proposed purchase, merger or acquisition,
                  provided that we inform the buyer it must use your personal
                  information only for the purposes disclosed in the Privacy
                  Policy. To any other person with your consent to the
                  disclosure.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What are my data protection rights and how do I exercise them?
                </Accordion.Header>
                <Accordion.Body>
                  You have the right to access, correct, update or request
                  deletion of your personal information. If you are a resident
                  of the United Kingdom or European Union, you can object to
                  processing of your personal information, ask us to restrict
                  processing of your personal information or request portability
                  of your personal information. If we have collected and
                  processed your personal information with your consent, then
                  you can withdraw your consent at any time. Withdrawing your
                  consent will not affect the lawfulness of any processing we
                  conducted prior to your withdrawal, nor will it affect
                  processing of your personal information conducted in reliance
                  on lawful processing grounds other than consent. You have the
                  right to complain to a data protection authority about our
                  collection and use of your personal information. For more
                  information, please contact your local data protection
                  authority. To submit your request please email our Data
                  Protection Officer at dpo@cappfinity.com and, where possible,
                  include the following information: Your full name. The email
                  address used. The data protection right you would like to
                  exercise. The type of assessment you took (if applicable). The
                  name of the organization you took the assessment for (if
                  applicable). We respond to all requests we receive from
                  individuals wishing to exercise their data protection rights
                  in accordance with applicable data protection laws. Please
                  note: If you provided your personal data to us as part of a
                  process for a specific organization (for example, but not
                  limited to, a hiring employer), we must have the
                  organization’s express permission as your Data Controller
                  before we are able to fulfil your request.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  I have a question regarding my personal data that isn’t listed
                  here, who do I speak to?
                </Accordion.Header>
                <Accordion.Body>
                  Please contact our Data Protection Officer at{' '}
                  <a href="mailto:dpo@cappfinity.com">dpo@cappfinity.com</a>.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(Faqs, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
