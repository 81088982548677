import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardCard from '../components/sidebar-card-card';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';

export function Resilience() {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE14_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-fourteen capp-gradient"
        heading="Resilience: The Power to Persevere"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p>
          Resilience is a cornerstone of success in the world of work. It is the capacity to recover quickly from difficulties, to adapt in the face of adversity, and to keep going when things get tough.
          </p>
          <p>
          Resilience is invaluable for problem-solving, as it encourages you to approach obstacles as challenges to overcome rather than insurmountable issues. It boosts productivity, as resilient individuals are less likely to be deterred by setbacks. Additionally, resilience enhances emotional well-being, helping you cope with stress and maintain a positive outlook.
          </p>
          <p />
          <p>
            <span id="card-emphasis">
            Several skills align with resilience...
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Emotional Intelligence']}
              text={[
                'Understanding and managing your emotions aids in developing resilience.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Problem-Solving']}
              text={[
                'Resilient people see problems as opportunities to learn and grow.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Flexibility']}
              text={[
                'Being open to change and able to adapt is key to resilience.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p>
            <span id="card-emphasis">
            Here are some strategies to enhance your resilience:
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Practice Self-Care']}
              text={[
                'Regular exercise, a healthy diet, and adequate sleep can improve your physical resilience and emotional well-being.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Build Strong Relationships']}
              text={[
                'A supportive network can provide comfort and practical assistance during challenging times.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Cultivate a Positive Mindset']}
              text={[
                'Practice mindfulness or other stress-reduction techniques. Embrace a growth mindset, seeing challenges as opportunities for learning.',
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['4.']}
              heading={['Learn from Mistakes']}
              text={[
                'Instead of dwelling on failures, consider them as stepping stones towards success. Identify lessons learned and how you can apply them in the future.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p />
          <p>
            <span id="card-emphasis">Things to think about…</span>
          </p>
          <p />
          <p>
          Resilience is not about avoiding stress or hardship, but about learning to cope effectively. By bolstering your resilience, you can navigate the challenges of the workplace with grace and strength, turning obstacles into opportunities for growth.
          </p>
        </Row>
        <Row>
        <Button onClick={() => navigate(-1)}>Back</Button>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(Resilience, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
