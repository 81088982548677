import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';

export function Legal() {
  
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Legal</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-fifteen capp-gradient"
        heading="Legal"
        subheading=""
      />
      <Container className="cards-wrapper">
        <Row>
         

          <Col xs={12}>
            Legal text here
            
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(Legal, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
