import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';

export function PracticeZone() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE1_TITLE}</title>
      </Helmet>

      <SidebarHeader
        class="sidebar-header page-one capp-gradient"
        heading="Pre-skilling Zone"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={6} style={{ paddingRight: '40px' }}>
            <p className="intro">
              This site has been developed with our Talent Partner, Cappfinity,
              and is dedicated to helping you learn more about yourself and
              setting you up for success if you are thinking about making an
              application to one of our roles, or roles outside of Liberty
              Mutual.
            </p>

            <p>
              Success starts with true self-awareness within the Pre-skilling
              Zone. You can explore and find out more about your values,
              preferences and your learning styles, as well as gain insight into
              your skills.
            </p>

            <p>
              If you’re ready to learn more about careers in the insurance
              industry, our job simulation is a great way to start to explore if
              it’s right for you.
            </p>
          </Col>
          <Col xs={12} lg={6}>
            <div className="checklist-card">
              <Col lg={12}>
                <h2>What's available...</h2>
              </Col>
              <ol>
                <li>
                  Complete your profile and let us know a bit about you and your
                  experiences to date
                </li>
                <li>
                  Explore examples and practice test questions to learn more
                  about how you will be assessed
                </li>
                <li>
                  Put this learning together and try out one of our practice
                  assessments
                </li>
                <li>Explore our hints and tips section to learn even more</li>
              </ol>
              <motion.button
                className="btn btn-secondary-logged-in"
                variant="secondary"
                onClick={(e) => {
                  window.location.pathname = '/learn-about-you/';
                  e.target.disabled = 'true';
                }}
              >
                Start now
              </motion.button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(PracticeZone, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
