import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardCard from '../components/sidebar-card-card';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';

export function Collaboration() {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE8_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-eight capp-gradient"
        heading="The Magic of Collaboration"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p>
            Collaboration is a vital component in the world of work. It's about
            coming together, leveraging individual strengths, and working
            towards a common goal. In an increasingly interconnected world, the
            power of collective brainpower can't be underestimated.
          </p>
          <p>
            Collaboration is key to innovation; it fosters creativity by
            combining diverse perspectives. It also aids in problem-solving, as
            people pooling their resources can often find solutions faster.
            Moreover, collaboration enhances learning, as individuals exchange
            knowledge and learn from each other.
          </p>
          <p />
          <p>
            <span id="card-emphasis">
              Several skills are aligned with collaboration...
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Communication']}
              text={[
                'Effective communication ensures everyone understands the team goals, tasks, and feedback.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Emotional Intelligence']}
              text={[
                "Understanding and managing your own emotions and empathizing with others' feelings are crucial for resolving conflicts and building a positive team environment.",
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Adaptability']}
              text={[
                'In a team, plans can change quickly. Being able to adapt ensures the collective effort remains uninterrupted.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p>
            <span id="card-emphasis">
              Here are some strategies to improve your collaboration…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Active Listening']}
              text={[
                "Make sure you fully understand others' ideas before responding. Ask clarifying questions if needed.",
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Clear Communication']}
              text={[
                'Be transparent about your ideas, progress, and challenges. Regular updates can help keep everyone on the same page.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Embrace Diversity']}
              text={[
                'Respect different ideas and perspectives. Everyone has unique strengths and insights that can benefit the team.',
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['4.']}
              heading={['Conflict Resolution']}
              text={[
                'Conflict can be healthy if handled constructively. If disagreements arise, focus on the problem, not the person, and seek a solution that benefits the whole team.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p />
          <p>
            <span id="card-emphasis">Things to think about…</span>
          </p>
          <p />
          <p>
            Embracing collaboration not only improves productivity and
            creativity but also makes the workplace more enjoyable and
            fulfilling. By improving your collaboration skills, you'll become a
            team player who can contribute significantly to the collective
            success.
          </p>
        </Row>
        <Row>
        <Button onClick={() => navigate(-1)}>Back</Button>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(Collaboration, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
