import React from 'react';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import { NavDropdownMenu } from 'react-bootstrap-submenu';
import 'react-bootstrap-submenu/dist/index.css';
import PropTypes from 'prop-types';

export function Sidebar(props) {
  const pathname =
    typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <div className="sidebar-wrapper">
      <Navbar expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav">Menu</Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" activeKey={pathname}>
              <ul className="sidebar-list">
                <li>
                  <Nav.Link
                    href={props.link1}
                    aria-current={pathname === props.link1 ? 'page' : undefined}
                  >
                    {props.item1}
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link
                    className={props.navClass1}
                    href={props.link2}
                    aria-current={pathname === props.link2 ? 'page' : undefined}
                  >
                    {props.item2}
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link
                    href={props.link3}
                    aria-current={pathname === props.link3 ? 'page' : undefined}
                  >
                    {props.item3}
                  </Nav.Link>
                </li>
                <li>
                  <NavDropdownMenu
                    title="Accelerate Your Skills"
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item
                      // className={props.navClass2}
                      href="/accelerate-your-skills"
                      aria-current={
                        pathname === props.link3 ? 'page' : undefined
                      }
                    >
                      Accelerate Your Skills
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // className={props.navClass2}
                      href="/self-awareness"
                      aria-current={
                        pathname === props.link3 ? 'page' : undefined
                      }
                    >
                      Self-Awareness
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      // className={props.navClass2}
                      href="/collaboration"
                      aria-current={
                        pathname === props.link3 ? 'page' : undefined
                      }
                    >
                      Collaboration
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // className={props.navClass2}
                      href="/analysis"
                      aria-current={
                        pathname === props.link3 ? 'page' : undefined
                      }
                    >
                      Analysis
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // className={props.navClass2}
                      href="/personal-responsibility"
                      aria-current={
                        pathname === props.link3 ? 'page' : undefined
                      }
                    >
                      Personal Responsibility
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // className={props.navClass2}
                      href="/curiosity"
                      aria-current={
                        pathname === props.link3 ? 'page' : undefined
                      }
                    >
                      Curiosity
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // className={props.navClass2}
                      href="/clarity"
                      aria-current={
                        pathname === props.link3 ? 'page' : undefined
                      }
                    >
                      Clarity
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // className={props.navClass2}
                      href="/drive"
                      aria-current={
                        pathname === props.link3 ? 'page' : undefined
                      }
                    >
                      Drive
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      // className={props.navClass2}
                      href="/resilience"
                      aria-current={
                        pathname === props.link3 ? 'page' : undefined
                      }
                    >
                      Resilience
                    </NavDropdown.Item>
                  </NavDropdownMenu>
                </li>
                <li>
                  <Nav.Link
                    className={props.navClass3}
                    href={props.link4}
                    aria-current={pathname === props.link4 ? 'page' : undefined}
                  >
                    {props.item4}
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href={props.link5}>{props.item5}</Nav.Link>
                </li>
                {props.extraLinks?.map((item) => {
                  return (
                    <li>
                      <Nav.Link href={item.url}>{item.text}</Nav.Link>
                    </li>
                  );
                })}
              </ul>
              <button
                className="btn btn-primary sidebar-button"
                variant="primary"
                onClick={() => window.open(props.buttonLink, '_blank')}
                style={{ display: 'none' }}
              >
                {props.buttonText}
              </button>
              <button
                className="btn btn-secondary sidebar-button-two"
                variant="secondary"
                onClick={() => window.open(props.buttonLink2, '_blank')}
                style={{ display: 'none' }}
              >
                {props.buttonText2}
              </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="powered-by-capp">
        <img src={props.image} alt="Powered by Cappfinity Logo" />
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  link3: PropTypes.string,
  item3: PropTypes.string,
};

export default Sidebar;
