import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';

export function Policies() {
  
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Policies</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-fifteen capp-gradient"
        heading="Policies"
        subheading=""
      />
      <Container className="cards-wrapper">
        <Row>
          <Col sm={4}><div className='policy'><a href="https://libertymutualgroup.com/about-lm/our-company/web-accessibility-policy" target="_blank" rel="noreferrer">Web Accessibility Policy (WCAG)</a></div> </Col>
          <Col sm={4}> <div className='policy'><a href="https://libertymutualgroup.com/about-lm/our-company/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a></div></Col>
          <Col sm={4}><div className='policy'><a href="https://libertymutualgroup.com/about-lm/our-company/terms-and-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a></div></Col>
        </Row>
   <p />
        <Row>
        <Col sm={4}><div className='policy'><a href="https://business.libertymutualgroup.com/business-insurance/coverages/workers-compensation" target="_blank" rel="noreferrer">Workers Compensation</a></div> </Col>
          <Col sm={4}> <div className='policy'><a href="https://privacyportal.onetrust.com/hosted-webform/consent/50577d86-8d39-4310-9583-33e6d3da8e17/9b108b70-4ef6-4d93-b7ec-60e41f03b292" target="_blank" rel="noreferrer">Your California Privacy Choices</a> </div></Col>
          <Col sm={4}><div className='policy'><a href="https://privacyportal.onetrust.com/hosted-webform/consent/50577d86-8d39-4310-9583-33e6d3da8e17/9b108b70-4ef6-4d93-b7ec-60e41f03b292" target="_blank" rel="noreferrer">Equal Opportunity Employer</a></div></Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(Policies, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
