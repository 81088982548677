import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardCard from '../components/sidebar-card-card';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';

export function PersonalResponsibility() {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE10_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-ten capp-gradient"
        heading="Embracing Personal Responsibility"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p>
            Personal responsibility plays a pivotal role in the world of work.
            It's about owning your actions, decisions, and their outcomes. This
            attribute instills trust and reliability, paving the way for
            professional growth.
          </p>
          <p>
            Personal responsibility enhances accountability, driving you to
            deliver on your commitments. It fosters integrity, as you become
            more conscientious about your actions and their impact. Moreover,
            personal responsibility can boost your self-confidence as you
            recognize and embrace your role in your success and setbacks.
          </p>
          <p />
          <p>
            <span id="card-emphasis">
              Several skills align with personal responsibility…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Time Management']}
              text={[
                'Prioritizing tasks and managing time effectively demonstrate responsibility.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Decision-Making']}
              text={[
                'Taking ownership of your choices and their consequences is crucial.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Problem Solving']}
              text={[
                'Acknowledging mistakes and proactively finding solutions showcases responsible behavior.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p>
            <span id="card-emphasis">
              Here are some strategies to boost your personal responsibility…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Set Clear Goals']}
              text={[
                'Having defined goals can direct your focus and motivate you to take ownership of tasks.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Embrace Mistakes']}
              text={[
                'Instead of blaming others or external factors for mistakes, take them as learning opportunities to improve.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Practice Time Management']}
              text={[
                'Use planners, digital tools, or time blocking methods to manage your tasks effectively.',
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['4.']}
              heading={['Seek Feedback']}
              text={[
                'Regular feedback can provide insights into areas where you can take more responsibility.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p />
          <p>
            <span id="card-emphasis">Things to think about…</span>
          </p>
          <p />
          <p>
            Embracing personal responsibility at work can not only enhance your
            professional reputation but also give you a greater sense of control
            over your career trajectory. By adopting a mindset of personal
            responsibility, you can become a more reliable and valued member of
            your team.
          </p>
        </Row>
        <Row>
        <Button onClick={() => navigate(-1)}>Back</Button>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(PersonalResponsibility, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
