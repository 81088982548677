import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader} from '@capp.micro/shared/components';
import SidebarCardAlternate from '../components/sidebar-card-alternate';
import { getApiUserMetadata } from '@capp.micro/shared/api';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';

export function LearnAboutYou() {

  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
 const stepArray = []
  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';

  const stepNames = ['step1Complete', 'step2Complete', 'step3Complete']

 if (isAuthenticated && userMetadata) {
  stepNames.forEach(step => userMetadata[`${Brand.FLAG_NAME}${step}`] ? stepArray.push(true) : stepArray.push(''))
 }

 const createSidebarCard = (alt, cardClass, heading, text, step, link) => (
  <SidebarCardAlternate
  alt={alt}
  class={cardClass}
  heading={heading}
  text={text}
  disabled={step}
  buttonText={step ? 'Completed' : 'Start Now'}
  link={link} 
  />

);

const valuesSidebarCard = () => {
  const step = stepArray[0];
  const link = `${Brand.ASSESSMENT_LINKS.VALUES_LINK}&cuid=${userID}&fname=${fname}&lname=${lname}&email=${email}&auid=${Brand.AUID}`;

  return createSidebarCard(
    Brand.BRAND_NAME,
    'sidebar-card card-one',
    ['Values', <br key="br1" />, <br key="br2" />],
    ["Begin to understand what your values are and how they may align to ours."],
    step,
    link
  );
};

const preferencesSidebarCard = () => {
  const step = stepArray[1];
  const link = `${Brand.ASSESSMENT_LINKS.PREF_LINK}&cuid=${userID}&fname=${fname}&lname=${lname}&email=${email}&auid=${Brand.AUID}`;

  return createSidebarCard(
    Brand.BRAND_NAME,
    'sidebar-card card-two',
    ['Preferences', <br key="br1" />, <br key="br2" />],
    ["Answer these short questions to help understand what type of work you might be best suited to."],
    step,
    link
  );
};

const learningStylesSidebarCard = () => {
  const step = stepArray[2];
  const link = `${Brand.ASSESSMENT_LINKS.LEARNING_LINK}&cuid=${userID}&fname=${fname}&lname=${lname}&email=${email}&auid=${Brand.AUID}`;

  return createSidebarCard(
    Brand.BRAND_NAME,
    'sidebar-card card-three',
    ['Learning Styles', <br key="br1" />, <br key="br2" />],
    ["A short experience to help you build a greater appreciation of how best you might learn new things."],
    step,
    link
  );
};

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE2_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-two capp-gradient"
        heading="Learn About You"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={4}>
          {valuesSidebarCard()}
          </Col>
          <Col xs={12} lg={4}>
          {preferencesSidebarCard()}
          </Col>
          <Col xs={12} lg={4}>
          {learningStylesSidebarCard()}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(LearnAboutYou, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
