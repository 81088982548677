import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardAlternate from '../components/sidebar-card-alternate';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';

export function AccelerateSkills() {

  const learnMore = 'Learn more'

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE3_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-four capp-gradient"
        heading="Accelerate Your Skills"
        subheading=""
      />

<Container className="cards-wrapper">
        <Row style={{marginBottom: "25px"}} >
          <Col xs={12} lg={4}>
            <SidebarCardAlternate
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-one self skill"
              heading={['Self-Awareness']}
              text={[
                'Understanding more about your own behavior, emotions and responses to different situations.',
                
              ]}
              disabled={''}
              buttonText={ learnMore }
              link="/self-awareness"
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardAlternate
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-two collab skill"
              heading={['Collaboration']}
              text={[
                'Being energized by the opportunity to collaborate and support your colleagues. ',
              ]}
              disabled={''}
              buttonText={ learnMore }
              link="/collaboration"
          
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardAlternate
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-three analysis skill"
              heading={['Analysis']}
              text={[
                'Methodically analyzing and interpreting problems, numbers and complex information.',
              ]}
              disabled={''}
              buttonText={ learnMore }
              link="/analysis"
              
            />
          </Col>
        </Row>
        <Row style={{marginBottom: "25px"}}>
          <Col xs={12} lg={4}>
            <SidebarCardAlternate
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-one personal skill"
              heading={['Personal Responsibility']}
              text={[
                'Assuming full responsibility for your actions and delivering on promises.',
                
              ]}
              disabled={''}
              buttonText={ learnMore }
              link="/personal-responsibility"
              
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardAlternate
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-two curiosity skill"
              heading={['Curiosity']}
              text={[
                'Being naturally inquisitive and constantly seeking new information.',
              ]}
              disabled={''}
              buttonText={ learnMore }
              link="/curiosity"
              
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardAlternate
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-three clarity skill"
              heading={['Clarity']}
              text={[
                'The ability to effectively explain complex issues clearly to others in a way that is easy to understand.',
              ]}
              disabled={''}
              buttonText={ learnMore }
              link="/clarity"
            
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCardAlternate
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-one drive skill"
              heading={['Drive']}
              text={[
                'Being self-motivated and pushing yourself to achieve your goals.',
                
              ]}
              disabled={''}
              buttonText={ learnMore }
              link="/drive"
              
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardAlternate
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-two resiliance skill"
              heading={['Resilience']}
              text={[
                'Being determined and having a positive attitude in overcoming obstacles.',
              ]}
              disabled={''}
              buttonText={ learnMore }
              link="/resilience"
              
            />
          </Col>

          <Col xs={12} lg={4}>
            
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(AccelerateSkills, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
