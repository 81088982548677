import React from 'react';
import poweredLogo from './images/powered-by-capp.svg';
import { learnAboutYouRedirect, beRecruitmentReadyRedirect, preSkillingZoneRedirect } from "./routes/redirectRoutes";

const TenantIdsPerEnvironment = {
  DEVELOPMENT: '6a456d5f-628d-47ee-b2f8-4022eb5933e4',
  TEST: '1e948c1e-4377-405f-90a0-c28758a6db3b',
  LIVE: 'aebf0c1b-2198-4449-8589-b51ce591dec4',
};

const tenantIdToUse = (() => {
  if (
    TenantIdsPerEnvironment.DEVELOPMENT &&
    typeof window !== 'undefined' &&
    window.environment === 'dev'
  ) {
    return TenantIdsPerEnvironment.DEVELOPMENT;
  } else if (
    TenantIdsPerEnvironment.TEST &&
    typeof window !== 'undefined' &&
    window.environment === 'TEST'
  ) {
    return TenantIdsPerEnvironment.TEST;
  } else {
    return TenantIdsPerEnvironment.LIVE;
  }
})();


const AssessmentLinks = {
  DEVELOPMENT: null,
  TEST: {
    PROFILE_LINK:
      '',
    VALUES_LINK:
      'https://apitake-test.cappassessments.com/Assessments/PackagedAssessment/Register?k=EH_USQWjoUC7WmB4b1rLBg',
    PREF_LINK:
      'https://apitake-test.cappassessments.com/Assessments/PackagedAssessment/Register?k=xlJe81WzJkW_q9XIwb4llA',
    LEARNING_LINK:
      'https://apitake-test.cappassessments.com/Assessments/PackagedAssessment/Register?k=dC2xvgEU-keBlKcGHhj6sQ',
    WORK_SIM_LINK:
      'https://apitake-test.cappassessments.com/Assessments/PackagedAssessment/Register?k=LctJBXOiJE-1Fge4a4fobg',
    COG_LINK:
      'https://apitake-test.cappassessments.com/Assessments/PackagedAssessment/Register?k=80aRTB6NEkaoN-T10tc4dQ',
    TRIAL_LINK:
      'https://apitake-test.cappassessments.com/Assessments/PackagedAssessment/Register?k=3yW_uLP17k6wqN8vXSKYiA',
    SHOWCASE_LINK:
      'https://apitake-test.cappassessments.com/Assessments/PackagedAssessment/Register?k=VLlcHGI7c0Sr8aapoPI9hw',
  },
  LIVE: {
    PROFILE_LINK:
    '',
    VALUES_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=A8KdXbsoAEaNbv2vEtQu3Q',
    PREF_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=jx3yO4yRz0CcWWrtUTsBBg',
    LEARNING_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=QeWOL6q6PESb0krtU1wWkA',
    WORK_SIM_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=TYrUm5DDA0GZKNcY_HsAcA',
    COG_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=v01xsfRjRE2sDredBF3JCA',
    TRIAL_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=4vdPDWKDkEWmPrt70PpyUA',
    SHOWCASE_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=AoTgWH75nUiFwqtmHLlG-Q',
    CODING_LINK: '',
  },
};

const assessmentLinksToUse = (() => {
  if (typeof window !== "undefined" && AssessmentLinks.DEVELOPMENT && window.environment === 'DEV') {
    return AssessmentLinks.DEVELOPMENT;
  } else if (typeof window !== "undefined" && AssessmentLinks.TEST && window.environment === 'TEST') {
    return AssessmentLinks.TEST;
  } else {
    return AssessmentLinks.LIVE;
  }
})();

const routeMapping = {
  profileComplete: learnAboutYouRedirect,
  step1Complete: learnAboutYouRedirect,
  step2Complete: learnAboutYouRedirect,
  step3Complete: learnAboutYouRedirect,
  step4Complete: beRecruitmentReadyRedirect,
  step5Complete: beRecruitmentReadyRedirect,
  step6Complete: beRecruitmentReadyRedirect,
  step7Complete: preSkillingZoneRedirect,
};

const Brand = {
  BRAND_NAME: 'LibertyMutual',
  FLAG_NAME: 'LibertyMutual',
  ASSESSMENT_LINKS: assessmentLinksToUse,
  TENANT_ID: tenantIdToUse,
  ROUTE_MAPPING: routeMapping,
  AUID: '24f14667-7555-430c-8bda-96bf5e114b05',

  HOME_PAGE_TITLE: 'Liberty Mutual Preparation Plus',
  PAGE1_TITLE: 'Pre-skilling Zone - Liberty Mutual Preparation Plus',
  PAGE2_TITLE: 'Learn about you - Liberty Mutual Preparation Plus',
  PAGE3_TITLE: 'Accelerate Your Skills',
  PAGE4_TITLE:
    'Be Recruitment Ready - Liberty Mutual Preparation Plus',
  PAGE5_TITLE: 'Insurance Career & You - Liberty Mutual Preparation Plus',
  PAGE6_TITLE: 'FAQs - Liberty Mutual Preparation Plus',
  PAGE7_TITLE: 'Self Awareness - Liberty Mutual Preparation Plus',
  PAGE8_TITLE: 'Collaboration - Liberty Mutual Preparation Plus',
  PAGE9_TITLE: 'Analysis - Liberty Mutual Preparation Plus',
  PAGE10_TITLE: 'Personal Responsibility - Liberty Mutual Preparation Plus',
  PAGE11_TITLE: 'Curiosity - Liberty Mutual Preparation Plus',
  PAGE12_TITLE: 'Clarity - Liberty Mutual Preparation Plus',
  PAGE13_TITLE: 'Drive - Liberty Mutual Preparation Plus',
  PAGE14_TITLE: 'Resilience - Liberty Mutual Preparation Plus',
  PAGE15_TITLE: 'LinkedIn - Liberty Mutual Preparation Plus',

  SIGN_UP_BG: 'HSBC/hsbc-auth0-bg.png',
  SIGN_UP_LOGO: 'LibertyMutual/lm-logo.png',
  SIGN_UP_BUTTON_COLOR: '1A1446',
  SIGN_UP_BUTTON_HOVER: '367AAC',
  BUTTON_TEXT_COLOR: 'FFFFFF',
  SIGN_UP_HEADER_COLOR: 'FFD000',
  WELCOME_TEXT: 'Welcome To Liberty Mutual',
  WELCOME_TEXT_COLOR_ONE: '000000',
  WELCOME_TEXT_COLOR_TWO: '000000',
  SIGN_UP_TITLE: 'Sign up',
  LOG_IN_TITLE: 'Log in',

  AUTH_CONNECTION: 'LibertyMutual-PreparationPlus',
  POWERED_BY_CAPP_LOGO: poweredLogo,
  LOGO_ALT: 'Liberty Mutual Preparation Plus',
};

export default Brand;


