import React from "react"
import Brand from "../Brand";
import Layout from "../components/layout";
import { Button, Col, Container, Row, } from "react-bootstrap"
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet"
import { navigate } from "gatsby";

const Index = (_props) => {

  const { isAuthenticated, loginWithRedirect, user } = useAuth0();


  return (
  
    <> { isAuthenticated && user ?
    navigate("/dashboard") : (
    <Layout>
    <Helmet>
          <meta charSet="utf-8" />
          <title>{Brand.HOME_PAGE_TITLE}</title>
        </Helmet>
      <Container className="jumbo-section hero full-width hero-light" style={{ zIndex: "2", height: "100vh", marginBottom: "0" }}>
        <Row style={{paddingTop: "1rem"}}>
          <Col xs={12} lg={6} className="text-container">
             <h3 style={{color: "#fff"}}>
             Welcome to
            </h3>
            <h1 className="sign-up-title" style={{paddingLeft: "0px"}}>
            Pre-Skilling Zone
            </h1>
           

            <div className="button-container">
        
                  <Button variant="secondary" onClick={() => loginWithRedirect({
                   dbConnection: Brand.AUTH_CONNECTION, backgroundImage: Brand.SIGN_UP_BG, logo: Brand.SIGN_UP_LOGO, buttonColor: Brand.SIGN_UP_BUTTON_COLOR, buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER, buttonTextColor: Brand.BUTTON_TEXT_COLOR, headerColor: Brand.SIGN_UP_HEADER_COLOR, welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE, welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO, welcomeTwo: Brand.WELCOME_TEXT, appName: Brand.LOG_IN_TITLE})} className="auth-button">Login</Button>
                 

                  <Button variant="secondary" onClick={()=>loginWithRedirect({
                    dbConnection: Brand.AUTH_CONNECTION, 
                    accountConfirmationType:"open-signup", 
                    blankRegistrationFieldsMessage:"Please fill all fields below", 
                    backgroundImage: Brand.SIGN_UP_BG,
                    logo: Brand.SIGN_UP_LOGO,
                    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
                    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
                    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
                    headerColor: Brand.SIGN_UP_HEADER_COLOR,
                    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
                    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
                    welcomeTwo: Brand.WELCOME_TEXT,
                    appName: Brand.SIGN_UP_TITLE})} className="auth-button">Sign up</Button>

    

            </div>  
          </Col>
        </Row>
      </Container>
    </Layout>
    )}
    </>
  )
};

export default Index
