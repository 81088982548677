import { Link } from "gatsby"
import React from "react"
import { Navigation } from "@capp.micro/shared/components";
import logo from "../images/brand-logo.png"
import Brand from "../Brand"
export function Header({ siteTitle, props }) {
  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    > 
  
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `100%`,
          padding: `1.45rem 0`,
        }}
      
      >
     
        <Navigation
        useApiEndpoints
          logo={logo}
          name="Liberty Mutual"
          alt={Brand.LOGO_ALT} />
      </div> 
      
    </header>
  )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
