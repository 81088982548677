import React from 'react';
import {withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardCard from '../components/sidebar-card-card';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';

export function Analysis() {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE9_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-nine capp-gradient"
        heading="Unlocking the Power of Analysis"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p>
            In today's data-driven world, the ability to analyze information
            effectively is a significant asset. Analysis involves examining data
            or situations systematically to gain insights and inform
            decision-making. It's essential across industries, be it in
            interpreting market trends for business strategies or decoding user
            behavior in tech products.
          </p>
          <p>
            Analysis boosts problem-solving by revealing patterns, potential
            causes, and solutions. It supports informed decision-making,
            replacing guesswork with evidence. It also enables foresight, as
            spotting trends can help anticipate and prepare for future
            scenarios.
          </p>
          <p />
          <p>
            <span id="card-emphasis">
              Several skills are aligned with analysis...
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Critical Thinking']}
              text={[
                'This allows you to objectively evaluate information from different perspectives.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Attention to Detail']}
              text={[
                'Spotting nuances or irregularities can reveal important insights.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Quantitative Skills']}
              text={[
                'Numerical data often hold key information, and being comfortable with numbers helps in extracting these insights.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p>
            <span id="card-emphasis">
              Here are some strategies to enhance your analytical skills…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Continued Learning']}
              text={[
                'Regularly update your knowledge of analytical methods, tools, and best practices. Online courses are a great resource for this.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Practice']}
              text={[
                'Use analytical tools in everyday tasks, even those unrelated to work. This could be anything from analyzing your personal budget to interpreting social media trends.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Problem Solving']}
              text={[
                'Regularly challenge yourself with puzzles, games, or brainteasers that require analytical thinking.',
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['4.']}
              heading={['Seek Feedback']}
              text={[
                'Discuss your analyses with peers or mentors. Their perspectives can highlight areas for improvement or alternative approaches.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p />
          <p>
            <span id="card-emphasis">Things to think about…</span>
          </p>
          <p />
          <p>
            Harnessing the power of analysis can help you uncover the story
            behind the data, transforming raw information into valuable
            insights. As your analytical skills grow, so will your ability to
            navigate the complexities of the workplace, and contribute to
            data-informed decision-making.
          </p>
        </Row>
        <Row>
        <Button onClick={() => navigate(-1)}>Back</Button>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(Analysis, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
