import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardCard from '../components/sidebar-card-card';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';

export function SelfAwareness() {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE7_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-seven capp-gradient"
        heading="The Power of Self-Awareness"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p>
            In the world of work, self-awareness is more than just understanding
            oneself; it's the foundation of effective communication,
            decision-making, and emotional intelligence. It's about knowing your
            strengths, weaknesses, emotions, and values, and how they impact
            your actions and decisions.
          </p>
          <p>
            The importance of self-awareness in the workplace cannot be
            overstated. It increases your adaptability, as knowing your skills
            and limitations helps you respond to changing circumstances. It
            improves your decision-making, as understanding your thought
            processes allows you to make informed choices. Moreover, being aware
            of your emotions enables you to manage them better, reducing
            workplace stress and enhancing interpersonal relationships.
          </p>
          <p />
          <p>
            <span id="card-emphasis">
              Several skills are aligned with self-awareness…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Emotional Intelligence']}
              text={[
                'The ability to identify and manage your emotions, and empathize with others. This leads to effective communication and strong relationships.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Critical Thinking']}
              text={[
                'Understanding your thought processes helps you analyze problems objectively and make rational decisions.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Mindfulness']}
              text={[
                'Staying present and focused improves your self-awareness by allowing you to fully experience your emotions and reactions.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p>
            <span id="card-emphasis">
              Here are some strategies to boost your self-awareness…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Reflection']}
              text={[
                'Dedicate time each day to reflect on your feelings, actions, and reactions. Writing in a journal can help make this a habit.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Feedback']}
              text={[
                'Regularly seek feedback from colleagues and superiors. They can provide insight into how your actions impact others.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Mindfulness Practices']}
              text={[
                'Techniques such as meditation, yoga, or simply paying attention to your breath can help you become more aware of your emotions and reactions in real-time.',
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['4.']}
              heading={['Personality Tests']}
              text={[
                'Tools like online personality tests or Strengths Profile can provide insights into your personality traits and strengths.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p />
          <p>
            <span id="card-emphasis">Things to think about…</span>
          </p>
          <p />
          <p>
            Incorporate self-awareness into your daily routine, and you'll soon
            notice its transformative impact on your work life. As you become
            more self-aware, you'll find yourself communicating more
            effectively, making better decisions, and becoming a more empathetic
            colleague.
          </p>
        </Row>
        <Row>
              <Button onClick={() => navigate(-1)}>Back</Button>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(SelfAwareness, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
