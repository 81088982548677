import React from 'react';
import {withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardCard from '../components/sidebar-card-card';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';

export function ResumeTips() {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE15_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-fifteen capp-gradient"
        heading="Resume Writing Simplified"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
            <h2 style={{marginBottom: '25px'}}>Purpose of a Resume</h2>
          <p>
          A resume highlights your skills, achievements, and history. Its goal? Get you that interview. Your resume should be a clear snapshot of your most relevant experiences that match the employer's needs. Don't forget to get someone to review it before you send it out!
          </p>
          
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
        <h2 style={{marginBottom: '25px'}}>Starting Point</h2>
          <p>
          Know yourself first. Think about your unique strengths, skills, and successes. This is what you're offering to the employer. If you're short on job experience, your leadership roles, volunteer work, and important school projects can also shine. Know what the job demands too. Research your chosen field. Do companies want similar qualifications? What's emphasized in the job listing? Make sure you highlight your experiences that match.
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
        <h2 style={{marginBottom: '25px'}}>Style Tips</h2>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Concise is Key']}
              text={[
                'Keep it short and to the point.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Be Specific']}
              text={[
                "For example, \"Boosted sales by 50% in one year\" is clearer than just \"Boosted sales\".",
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Avoid Jargon']}
              text={[
                'Use full terms, like "User Acceptance Testing" over "UAT". Your resume might be read by non-experts.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Stay Active']}
              text={[
                "Use active voice. Compare \"Managed a team\" with \"I was responsible for managing a team\". The first is clearer and stronger.",
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Action Verbs']}
              text={[
                'Start points with action words. Avoid starting with "Duties included".',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Cut Extra Words']}
              text={[
                'Leave out unnecessary words to keep it crisp.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '50px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Consistent Verb Tense']}
              text={[
                'Past roles? Use past tense. Current job? Use present tense.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Write for Your Reader']}
              text={[
                'Understand what the employer is looking for and tailor your resume to it. Different jobs might need different resumes.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Date Formats']}
              text={[
                'Be consistent in how you list dates.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
        <h2 style={{marginBottom: '25px'}}>Skills Section</h2>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Categories']}
              text={[
                'Group similar skills, like computer skills, language proficiency, or other technical skills into categories.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Be Specific']}
              text={[
                "If you're applying for a Real Estate role, and you know Argus, list it. For HR? Mention PeopleSoft.",
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['Rate Your Skill Level']}
              text={[
                'Use terms like "proficient", "familiar with", or "fluent" to describe your skill level.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
        <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual bolder-heading"
              number={['']}
              heading={['About English']}
              text={[
                'If your resume is in English, you don\'t need to mention you know English.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', marginTop: '50px', paddingRight: '200px' }}>
        <Button onClick={() => navigate(-1)}>Back</Button>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(ResumeTips, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
