import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardCard from '../components/sidebar-card-card';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';

export function Drive() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE13_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-thirteen capp-gradient"
        heading="Fuelling Success with Drive"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p>
          Drive, or self-motivation, is the internal fire that propels you to achieve. It's the catalyst for goal-setting, persistence, and resilience in the workplace. A driven professional is proactive, dedicated, and consistently seeks to surpass expectations.
          </p>
          <p>
          Drive enhances performance as it spurs you to push boundaries and achieve more. It fosters resilience, as a motivated person is more likely to bounce back from setbacks. It also signals leadership potential, as those with high drive are often proactive and take the initiative.
          </p>
          <p />
          <p>
            <span id="card-emphasis">
            Several skills align with drive…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Goal-setting']}
              text={[
                'Establishing clear, achievable goals is key to maintaining your motivation.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Time Management']}
              text={[
                'Effective use of time reflects and enhances drive.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Resilience']}
              text={[
                'The ability to cope with challenges and keep going is a hallmark of drive.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p>
            <span id="card-emphasis">
            Here are some strategies to fuel your drive…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Set Goals']}
              text={[
                'Having clear, meaningful goals gives you a sense of direction and purpose. Break them down into small, achievable steps to keep your momentum.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Celebrate Success']}
              text={[
                'Recognize and celebrate your accomplishments, no matter how small. This boosts your morale and motivation.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Seek Inspiration']}
              text={[
                'Surround yourself with people who inspire you. Read about successful individuals in your field to keep your drive alive.',
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['4.']}
              heading={['Maintain Work-Life Balance']}
              text={[
                'Ensure you\'re taking care of your physical and mental health. A healthy body and mind will support your drive.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p />
          <p>
            <span id="card-emphasis">Things to think about…</span>
          </p>
          <p />
          <p>
          In the world of work, drive isn't just about working hard; it's about working smart, being persistent, and staying motivated. Cultivate your drive, and you'll find yourself not just achieving, but surpassing your professional goals.
          </p>
        </Row>
        <Row>
        <Button onClick={() => navigate(-1)}>Back</Button>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(Drive, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
