import React from "react"
import { Col, Container, Row, } from "react-bootstrap"

export function Hero(props) {

  return (
    
      <Container className={props.class} style={{ zIndex: "2" }}>
        <Row>
          <Col xs={12} lg={6} className="text-container">
            <h3>{props.welcomeTitle}</h3>
            <h1>{props.title}</h1>
            <h2> {props.subtitle}</h2>
            {/* <p>
               <h4>Vee can support you in 3 essential ways</h4>
                <ul>
                  <li>Identify your strengths so you know what careers will be the best fit</li>
                  <li>Improve your skills in the areas employers want most</li>
                  <li>Experience work simulations to understand what it's really like to respond to workplace challenges</li>
                </ul>
            </p> */}

          </Col>
          <Col xs={12} lg={6} className="jumbo-right-image">
            <img src={props.image} alt={props.alt}/>

          </Col>
        </Row>
      </Container>
    
  )
}

export default Hero
