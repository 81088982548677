import React from 'react';
import {Container} from 'react-bootstrap';


export function SidebarCardCard(props) {
  return (
    <Container className={props.class}>
      <div className="card-image-main">
        <div className='card-image-sub'>
          <div className="card-sub-one">{props.number}</div>
          <div className="card-sub-two">{props.heading}</div>
        </div>
        <div className="card-sub-three">
          <p>{props.text}</p>
        </div>
      </div>
    </Container>
  );
}

export default SidebarCardCard;
