import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import spLogo from '../images/sp-logo.png';
import { Container, Row, Col } from 'react-bootstrap';

export const YourSkillsAndStrengths = () => {


  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE4_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-four capp-gradient"
        heading="Your Skills & Strengths"
        subheading=""
      />
      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={12}>
            <div className="practice-question-card">
              <img
                src={spLogo}
                alt="Strengths Profile"
                style={{ marginBottom: '20px', width: '260px' }}
              />
              <p>
                Now that you’ve learned more about yourself and had a chance at
                the practice tests, it’s time to find out your strengths so you
                can be confident in sharing your best self when it comes to
                interviews. Visit Strengths Profile, a Cappfinity product, to
                complete your Strengths Profile, revealing your top realized and
                unrealized strengths, learned behaviors and weaknesses.{' '}
              </p>
              <p>
                You will need to register to complete the Strengths Profile and
                be ready to take the questionnaire immediately. Please make sure
                you only complete this section when you have 15-20 minutes to focus
                on this. Your Strengths Profile will be private to you and won't
                be shared with employers or organizations. Cappfinity
                administrators will have access in case any troubleshooting
                needs to happen.{' '}
              </p>

              <p>
                Once completed, download your Strengths Profile and keep it safe in order to refer back to it for self-reflection.{' '}
              </p>

              <motion.button
                className="btn btn-primary"
                variant="primary"
                onClick={() =>
                  window.open(
                    'https://www.strengthsprofile.com/Codes/Redeem?code=Cbh7M-70QU7-ZAtOB-Xg25L-CqhmA-x6w4s',
                    '_blank', 'noopener'
                  )
                }
               
              >
                Start Now
              </motion.button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(YourSkillsAndStrengths, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
