import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { getApiUserMetadata } from '@capp.micro/shared/api';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';

export function PracticeAssessment() {
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const stepArray = []
  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';

    const stepNames = ['step7Complete']

 if (isAuthenticated && userMetadata) {
  stepNames.forEach(step => userMetadata[`${Brand.FLAG_NAME}${step}`] ? stepArray.push(true) : stepArray.push(''))
 }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE5_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-four capp-gradient"
        heading="Insurance Career & You"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={12}>
            <div className="practice-question-card">
              <p>
              By working through this experience, you will be presented with scenarios and settings that are commonplace in today's world of work at Liberty Mutual - allowing you to truly immerse yourself into the types of environments you could find yourself enjoying in the future if you were to make a successful application with us.
              </p>

              <p>
              Through your responses to the questions and challenges posed, you will ultimately be building an indicative profile of how you think, how you feel and how you act in certain settings and this is an excellent basis upon which we can begin to understand more about your suitability to our roles.
              </p>

              <p>
              Go at your own pace as nothing here is timed, try to complete the mock assessment in one go and be as honest as you can to ensure you give a true and accurate representation of yourself.
              </p>
              <motion.button
                className="btn btn-primary"
                variant="primary"
                onClick={(e) =>
                  {window.location.href = `${Brand.ASSESSMENT_LINKS.SHOWCASE_LINK}&cuid=${userID}&fname=${fname}&lname=${lname}&email=${email}&auid=${Brand.AUID}`;
                    e.target.disabled = "true";}
                }
                disabled={stepArray[0]}
              >
                {stepArray[0] ? 'Completed' : 'Start now'}
              </motion.button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(PracticeAssessment, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
