import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import SidebarCardCard from '../components/sidebar-card-card';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';

export function Curiosity() {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE11_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-eleven capp-gradient"
        heading="Cultivating Curiosity: A Career Catalyst"
        subheading=""
      />

      <Container className="cards-wrapper">
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p>
          Curiosity, the innate desire to know and understand, is a powerful skill in the workplace. It drives innovation, fuels learning, and fosters engagement. A curious mind questions, explores, and strives to see beyond the status quo.
          </p>
          <p>
          Innovation arises from curiosity as it inspires us to challenge the existing and imagine the new. It underpins lifelong learning, encouraging the pursuit of knowledge and skills. Curiosity also promotes engagement, as an inquisitive mind finds interest and satisfaction even in routine tasks.
          </p>
          <p />
          <p>
            <span id="card-emphasis">
            Several skills are closely tied to curiosity…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Open-mindedness']}
              text={[
                'Being open to new ideas and perspectives complements curiosity and fuels creativity.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Active Listening']}
              text={[
                'Curiosity nurtures the desire to genuinely understand others\' viewpoints.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Critical Thinking']}
              text={[
                'It goes hand in hand with curiosity, as questioning assumptions and probing deeper are part of both.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p>
            <span id="card-emphasis">
            Here are some strategies to foster curiosity…
            </span>
          </p>
        </Row>
        <Row style={{ marginBottom: '25px' }}>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['1.']}
              heading={['Be Open to Listening']}
              text={[
                'Embrace the mindset of a lifelong learner. Pursue learning opportunities outside your comfort zone.',
              ]}
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['2.']}
              heading={['Ask Questions']}
              text={[
                'Cultivate the habit of asking thoughtful questions. It encourages deeper understanding and fresh insights.',
              ]}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['3.']}
              heading={['Embrace Diversity']}
              text={[
                'Engage with people, ideas, and cultures different from your own. It enriches your perspective and stimulates curiosity.',
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCardCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card accelerate-card-individual"
              number={['4.']}
              heading={['Read Broadly']}
              text={[
                'Read across genres and disciplines. It exposes you to a wealth of ideas, encouraging a curious mind.',
              ]}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '25px', paddingRight: '200px' }}>
          <p />
          <p />
          <p>
            <span id="card-emphasis">Things to think about…</span>
          </p>
          <p />
          <p>
            In the world of work, curiosity isn't just about asking questions; it's about seeking out the answers. By cultivating curiosity, you're not only enhancing your own work experience but also potentially driving innovation and growth in your professional environment.
          </p>
        </Row>
        <Row>
        <Button onClick={() => navigate(-1)}>Back</Button>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(Curiosity, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
