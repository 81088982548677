import React, { useEffect, useState } from 'react';
import Hero from '../components/hero';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Layout from '../components/layout';
import Brand from '../Brand';
import { getApiUserMetadata, completeStep } from '@capp.micro/shared/api';

const  Complete = () => {
  const [, setUserMetadata] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();
  
  useEffect(() => {
    const updateUserMetadata = async () => {
      completeStep(Brand, user, getAccessTokenSilently, setUserMetadata, true);
    };

    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
    updateUserMetadata();
  }, []);

  return (
    <Layout>
      <Hero
        welcomeTitle="Thank you"
        title=""
        subtitle="You will be redirected shortly"
        text=""
        class="jumbo-section hero full-width hero-light capp-gradient"
      />
    </Layout>
  );
}

export default withAuthenticationRequired(Complete, {
  dbConnection: Brand.AUTH_CONNECTION,
  backgroundImage: Brand.SIGN_UP_BG,
  logo: Brand.SIGN_UP_LOGO,
  buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
  buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
  buttonTextColor: Brand.BUTTON_TEXT_COLOR,
  headerColor: Brand.SIGN_UP_HEADER_COLOR,
  welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
  welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
  welcomeTwo: Brand.WELCOME_TEXT,
  appName: Brand.LOG_IN_TITLE,
});
